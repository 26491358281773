.formContainer {
  @apply relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white text-primary overflow-auto;
}

.formUsersError {
  @apply text-alert font-medium text-xs text-left absolute top-0 transition-all;
}

.formUsersHeader {
  @apply block text-primary text-sm mb-2;
}

.formUsersContainer {
  @apply flex justify-start pt-5 relative;
}

.formUsersProfilePicture {
  @apply w-44 h-44 flex items-center justify-center rounded-full cursor-pointer bg-background shadow active:ring-0 active:outline-2 active:border active:border-secondary ease-linear transition-all duration-150;
}

.formUsersInput {
  @apply border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150;
}

.formUsersdropdown {
  @apply w-full placeholder-blueGray-400 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150;
}

.formUsersDotted {
  @apply flex justify-center rounded flex-wrap cursor-pointer w-full h-auto border-2 border-dotted border-secondary p-2;
}

.dropdown {
  @apply text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-primary hover:bg-background;
}

.usersTableCell {
  @apply border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs py-2 break-words;
  word-wrap: anywhere;
}

.usersTableHeader {
  @apply flex flex-none px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left;
}

.usersBtn {
  @apply bg-secondary text-white active:bg-blueGray-600 text-sm font-medium uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150;
}

.him-users-footer {
  @apply w-full flex justify-end;
}

.card-body {
  @apply overflow-auto;
}

.alice-carousel__next-btn {
  position: absolute;
  top: 30%;
  right: 0px;
  text-align: right !important;
  & span {
    font-size: 30px;
  }
}

.alice-carousel__prev-btn {
  position: absolute;
  top: 30%;
  left: 0px;
  text-align: left !important;

  & span {
    font-size: 30px;
  }
}

.container-icon-upload {
  @apply flex justify-center items-center w-44 h-44 mx-auto bg-contain bg-no-repeat;
  background-image: url('/public/img/bg-upload.png') !important;
  & span {
    @apply mb-0;
  }
}

#form-file-upload {
  @apply w-full relative;
  & #input-file-upload {
    display: none;
  }
  & .drag-active {
    @apply flex items-center justify-center border-2 rounded-2xl border-dashed bg-white bg-opacity-50;
    min-height: 300px;
  }
  & #drag-file-element {
    @apply absolute p-4 w-full h-full rounded-2xl top-0 right-0 bottom-0 left-0;
    min-height: 300px;
  }
  & .image-preview {
    @apply w-72 h-72  bg-contain bg-no-repeat flex items-center justify-center mx-auto relative;
    background-image: url('/public/img/bg-upload.png');
  }
  & .button-delete {
    @apply absolute -top-4 -right-4 bg-white rounded-full p-3 cursor-pointer border-2 border-white;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
  & .image-preview .preview {
    @apply mx-auto bg-center bg-no-repeat bg-cover bg-white;
    max-width: 130px;
    max-height: 122px;
    filter: drop-shadow(0px 9.42786px 9.42786px rgba(0, 0, 0, 0.25));
    border-radius: 20% 20% 20% 20% / 35% 35% 35% 35%;
    width: 130px;
    height: 122px;
  }
}
.slider {
  @apply w-full flex flex-col items-center;
  & .alice-carousel__stage-item {
    @apply py-4;
  }
  & .image-preview {
    @apply w-72 h-72 p-4 bg-contain bg-no-repeat flex items-center justify-center mx-auto relative;
    background-image: url('/public/img/bg-upload.png');
  }
  & .button-delete {
    @apply absolute -top-4 -right-4 bg-white rounded-full p-3 cursor-pointer;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
  & .image-preview .preview {
    @apply mx-auto bg-center bg-no-repeat bg-contain bg-white;
    max-width: 140px;
    max-height: 132px;
    filter: drop-shadow(0px 9.42786px 9.42786px rgba(0, 0, 0, 0.25));
    border-radius: 20% 20% 20% 20% / 35% 35% 35% 35%;
    width: 140px;
    height: 132px;
  }
}
.urlPreview {
  @apply mx-auto bg-center bg-no-repeat bg-cover bg-white;
  max-width: 300px;
  max-height: 260px;
  filter: drop-shadow(0px 9.42786px 9.42786px rgba(0, 0, 0, 0.25));
  border-radius: 20% 20% 20% 20% / 35% 35% 35% 35%;
  width: 240px;
  height: 233px;
}

.button-delete {
  @apply absolute -top-0 -right-4 bg-white rounded-full p-3 cursor-pointer;
  & svg {
    width: 20px;
    height: 20px;
  }
}

.image-preview {
  @apply w-72 h-60 p-4 bg-contain bg-no-repeat flex items-center justify-center mx-auto relative;
  background-image: url('/public/img/bg-upload.png');
}

.drag-active {
  @apply flex items-center justify-center border-2 rounded-2xl border-dashed bg-white bg-opacity-50;
  min-height: 300px;
}

.profilePictureForm {
  @apply bg-white shadow-md rounded px-8 pt-6 pb-4 flex justify-center;
}

.profile-picture {
  @apply w-64 h-64 rounded-full cursor-pointer object-cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.non-profile-picture {
  @apply w-64 h-64 flex items-center justify-center rounded-full cursor-pointer bg-background;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.delete-profile-picture {
  @apply w-10 h-10 absolute flex justify-center items-center top-40 bg-background !important;
  border-radius: 20px;
  right: -12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.delete-photo {
  @apply w-10 h-10 absolute flex justify-center items-center top-48 bg-background z-10 !important;
  border-radius: 20px;
  right: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gallery-container {
  @apply overflow-auto;
  max-height: 66vh;
}

.review-footer {
  @apply flex justify-center items-center my-4;
}

.fullName {
  @apply underline not-italic ml-3 font-bold shadow-none capitalize text-xs bg-transparent text-blueGray-600 block self-center max-w-xs;
}

.dropdown-container {
  @apply absolute top-full left-0 z-[100]  flex flex-col rounded-xl bg-[#FFF];
}

.dropdown-item {
  @apply text-[#9362C7] text-[0.90rem] py-2 px-4 rounded-xl not-italic font-normal leading-[normal]  hover:bg-background;
}
