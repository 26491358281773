.sessions-card {
  @apply p-4 bg-background rounded-2xl;
  max-height: 85%;
  width: 85%;
  min-height: 25rem
}
.page-link {
  @apply text-primary;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  @apply text-secondary;
  z-index: 2;
  background-color: #f0f0f0ec;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}
.page-item.active .page-link {
  @apply bg-secondary border-secondary text-white z-10;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.current-coach-profile {
  @apply  h-2/4 flex items-center bg-secondary text-white border-2 border-white border-solid active:bg-blueGray-600 text-sm font-medium uppercase p-4 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150;
}