.description {
  @apply h-32 bg-purpleBack rounded-2xl p-4 w-2/4 ease-linear text-sm;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.roundedCard{
  @apply rounded-2xl px-8 pt-4 pb-8 w-11/12 ease-linear;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.coachInfo{
  @apply p-4 w-full;
}

.certificationsInfo{
  @apply flex justify-start items-center flex-col pt-5;
  width: 40%;
}

.calendarContainer{
  @apply p-4;

}

.linkBtn{
  @apply flex justify-center items-center bg-secondary text-white text-sm font-medium uppercase rounded shadow outline-none focus:outline-none mr-1 mb-1 p-0 w-6
}

.coach-default-add {
  @apply w-64 h-64 flex items-center justify-center cursor-pointer bg-background;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 55px;
}

.coach-profile-picture{
  @apply w-64 h-64 cursor-pointer object-cover;
  border-radius: 55px;
}

.coach-delete-picture {
  @apply w-10 h-10 absolute flex justify-center items-center top-40 left-60 bg-background !important;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.coach-delete-certification {
  @apply w-5 h-5 absolute flex justify-center items-center top-0 bg-background !important;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  right: -10px;
}

.coach-description {
  @apply pb-8 text-justify max-h-60 overflow-y-auto;
  width: 95%;
}
.coach-picture {
  @apply flex items-center;
  background-image: url('/public/img/bg-upload.png');
}
.coach-contact-info {
  @apply flex flex-col p-4;
  width: 60%
}

.formCoachesError {
  @apply w-60 text-alert font-medium text-xs flex justify-center transition-all;
}
.coach-preview {
  @apply mx-auto bg-center bg-no-repeat bg-white;
    max-width: 300px;
    max-height: 260px;
    filter: drop-shadow(0px 9.42786px 9.42786px rgba(0, 0, 0, 0.25));
    border-radius: 20% 20% 20% 20% / 35% 35% 35% 35%;
    width: 240px;
    height: 233px;
}
.gallery-coach {
  & .ReactGridGallery {
    @apply h-64;
    & .ReactGridGallery_tile {
      width: 50%;
    }
  }
}

.outlet-coach-profile{
  @apply flex justify-center w-full h-full;
  max-height: 90%;
}

.coach-availability-card{
  @apply w-2/4 h-fit overflow-auto p-2 rounded-3xl m-4 bg-background;
  box-shadow: 2.27108px 2.27108px 6.81325px rgba(0,0,0,.15);
}

.availability-blocks{
  @apply flex flex-col;
  max-height: 45vh;
}

.no-availability {
  @apply text-center text-2xl;
  min-height: 45vh;
}

.avContainer {
  @apply overflow-auto pr-2;
  max-height: 55vh;
  min-height: 55vh;
}

.newAvContainer{
  @apply rounded-lg bg-sweetPurple overflow-auto p-4;
  box-shadow: 1.1699717044830322px 2.3399434089660645px 2.3399434089660645px 0px rgba(0, 0, 0, 0.1);
  max-height: 60vh;
  min-height: 60vh;
  width: 100%;
}

.availabilitiesContainer{
  @apply p-4;
  max-height: 60vh;
  min-height: 60vh;
  height: 100%;
  width: 100%;
}

.coach-name {
  @apply underline flex not-italic justify-start ml-3 font-bold shadow-none capitalize text-xs bg-transparent text-blueGray-600;

}