.him-code{
  @apply px-2 mx-2 text-center;
  border-radius: 8px;
  max-width: 50px;
  height: 42px;
}

.him-footer{
  @apply mt-8 w-full flex justify-center;
}

.password-requirements{
  @apply text-primary text-sm text-center mt-8;
  width: 70%;
}

.login-user {
  @apply cursor-pointer hover:border-2 hover:shadow-lg object-cover;
  border-radius: 20px;
  border: 1px solid #d5c2d5;
  height: 95px;
}