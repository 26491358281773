.story-status{
  @apply flex items-center justify-center
}

.story-response {
  @apply overflow-auto;
  max-height: 10vh;
}

.back-btn-story {
  @apply text-sm font-medium rounded-xl focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 p-2 capitalize text-primary bg-white border-2 border-white border-solid;
}