.him-button {
  @apply bg-secondary text-white active:bg-blueGray-600 text-sm font-medium uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: inherit;
  border-top-left-radius: '30px';
}

.modal {
  @apply fixed inset-0 flex justify-center items-center;
  background-color: rgba (0, 0, 0, 0.5);
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff;
  border-radius: 20px;
}

.form-control.active\:ring-0.active\:outline-2.active\:border.active\:border-secondary.ease-linear.transition-all.duration-150.\!important {
  border-radius: 20px;
}

.react-tel-input .flag-dropdown {
  border: none !important;
}

.flag-dropdown.open {
  border: 1px solid #cacaca !important;
}

.flag-dropdown.open {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.selected-flag.open {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.selected-flag {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.form-control.active\:ring-0.active\:outline-2.active\:border.active\:border-secondary.ease-linear.transition-all.duration-150.\!important:focus {
  border-color: #9362c7;
  box-shadow: none !important;
}

.flag-dropdown:active:focus {
  border-color: #9362c7;
}
.react-tel-input:active:focus {
  border: 1px solid #9362c7;
}
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}
.him-inp-disabled {
  @apply bg-opacity-20 border-none font-medium;
}
.form-control.active\:ring-0.active\:outline-2.active\:border.active\:border-secondary.ease-linear.transition-all.duration-150.\!important {
  background-color: #fcf9ff;
}
.error {
  @apply ring-1 ring-alert border border-solid border-alert;
  border: 1px solid red;
}
.error1 {
  border: 1px solid red;
}

.him-pagination {
  @apply flex w-full justify-end py-3 pl-3 pr-8 !important;
}

.him-coaches {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #0983bd, #5b25a6);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-profile {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #0983bd, #5b25a6);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-stories {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #e9a800, #df4a2a);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-sidebar-friends {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #ab0000, #df4a2a);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-appointments {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #22a402, #0a19a5);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-sidebar-analytics {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #ea9651, #9764c1);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-sidebar-refunds {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #f72585, #4cc9f0);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-users {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #302150, #9362c7);
  -webkit-background-clip: text;
  background-clip: text;
}

.him-users:hover {
  background-color: #cac2db;
  border-radius: 15px;
}

.him-settings {
  @apply text-base;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  background: linear-gradient(90deg, #302150, #ea9651);
  -webkit-background-clip: text;
  background-clip: text;
}

.sidebar-item {
  @apply p-3 flex hover:rounded-2xl;
}

.him-popup {
  @apply flex justify-center;
}

.him-bg-modal {
  @apply w-full h-full fixed top-0 z-10 left-0 flex sm:items-center items-end justify-center;
  background: rgba(0, 0, 0, 0.4);
}

.him-container-modal {
  @apply pointer-events-auto flex flex-col items-center 2xl:w-2/5 lg:w-2/4 sm:w-3/4 w-full;
}
.him-close {
  @apply flex justify-end items-center px-2.5 w-full h-16 bg-bgTitleModal rounded-t-3xl;
}
.him-modal-body {
  @apply flex w-full bg-background sm:rounded-b-3xl;
  min-height: 255px;
  max-height: 80vh;
}

.options-card {
  @apply flex text-primary relative capitalize block p-4 cursor-pointer hover:opacity-80 text-left my-4 mx-2;
  background: linear-gradient(270deg, #ffffff 12.04%, #fbf8ff 96.32%);
  box-shadow: 2px 5px 8px #0000001a;
  border-radius: 20px;
}

.options-card-stories {
  @apply flex text-primary relative capitalize block p-4 text-left mx-2;
  background: linear-gradient(270deg, #ffffff 12.04%, #fbf8ff 96.32%);
  box-shadow: 2px 5px 8px #0000001a;
  border-radius: 20px;
}

.him-active-option {
  @apply font-normal;
}
.him-active-option::before {
  @apply absolute border-2 border-secondary top-0 bottom-0 right-0 left-0;
  content: '';
  border-radius: 20px;
}
.loading {
  @apply w-full h-full top-0 left-0 z-50 bg-primary bg-opacity-70 fixed items-center justify-center backdrop-blur-lg;
}

.loading .container-load {
  @apply bg-background rounded-3xl px-8 py-10 flex flex-col items-center h-52 sm:h-64 w-4/6 sm:w-3/6 lg:w-4/12 2xl:w-3/12;
  box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.51);
}

.loading .container-load .animation {
  @apply md:max-h-80;
  max-width: 14rem;
}

.tabs {
  height: 100%;
  min-height: 400px;
  background: #053742;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #e8f0f2;
  border-radius: 2rem;
}

.him-cancel {
  @apply text-sm font-medium rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 p-2 capitalize text-primary bg-white border-2 border-white border-solid !important;
}

.him-item {
  @apply flex justify-between text-sm sm:text-base px-5 py-5 h-32 sm:h-28 hover:opacity-80 transition-all font-light rounded-3xl text-primary bg-sweetPurple;
  box-shadow: 1.1699717044830322px 2.3399434089660645px 2.3399434089660645px 0px rgba(0, 0, 0, 0.1);
}

.datepicker {
  @apply left-0 bottom-0 w-full text-center;
  z-index: 1;
  font-size: 16px;
  box-sizing: content-box;
  -webkit-font-smoothing: antialiased;
  user-select: none;
}
.datepicker-header {
  padding: 0 0.5em;
  min-height: 2em;
  line-height: 2em;
  font-size: 1.125em;
}
.datepicker-navbar {
  padding: 0 0.5em 0.5em 0.5em;
  overflow: hidden;
  display: none;
}
.datepicker-navbar-btn {
  background-color: transparent;
  border: none;
  height: 2.5em;
  line-height: 2.5em;
  float: right;
  padding: 0 1em;
  cursor: pointer;
  &::focus {
    outline: none;
  }
}
.datepicker-caption {
  display: flex;
  padding: 0.5em 0.25em;
}
.datepicker-caption-item {
  flex: 1;
  margin: 0 0.25em;
  height: 40px;
  line-height: 40px;
  font-size: 1.2em;
}
.datepicker-content {
  @apply flex flex-row-reverse;
}
.datepicker-col-1 {
  flex: 1;
  margin: 0 0.25em;
}
.datepicker-viewport {
  height: 145px;
  position: relative;
  overflow: hidden;
}
.datepicker-viewport &::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.datepicker-wheel {
  position: absolute;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  width: 100%;
}
.datepicker-scroll {
  transform: translateY(calc(var(--translate-y) * 1px));
  margin-top: calc(var(--margin-top) * 1px);
  list-style-type: none;
  padding: 0;
  & > li {
    height: 40px;
    line-height: 40px;
    font-size: 1.375em;
    cursor: pointer;
  }
}
.datepicker-scroll.active {
  transition: transform 0.2s ease-out;
}

.ios {
  background-color: transparent;
}
.datepicker-col-1 {
  margin: 0;
}
.datepicker-header {
  color: rgb(59, 59, 59);
  padding: 0 3.5em;
  & + {
    .datepicker-content {
      padding-top: 0;
    }
  }
}
.datepicker-viewport {
  &::after {
    background: linear-gradient(
      #fdf7f7,
      rgba(245, 245, 245, 0) 52%,
      rgba(245, 245, 245, 0) 48%,
      #fef5f5
    );
  }
}
.datepicker-wheel {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.datepicker-caption-item {
  color: rgb(59, 59, 59);
}
.datepicker-scroll {
  & li {
    color: rgb(59, 59, 59);
  }
  & li.disabled {
    color: rgb(191, 191, 191);
  }
}
.datepicker-navbar {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #acacac;
}
.datepicker-navbar-btn {
  color: #007aff;
  &:nth-child(2) {
    float: left;
  }
}
.datepicker-caption {
  + {
    .datepicker-content {
      padding-top: 0;
    }
  }
}

#root {
  height: 100vh;
}

.bg-container {
  @apply relative md:ml-64 bg-blueGray-100;
  height: 113vh;
}

.side-container {
  @apply md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6;
}

.coach-detail {
  @apply relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary;
}
